.contact-infos{
  margin: 0 0 var(--msd-space-3xl) 0!important;
  @media screen and (min-width: 420.1px) {
    margin: 0 0 0 0!important;
  }
  h2{
    font-size: var(--msd-text-xl);
    margin-bottom: var(--msd-space-lg);
    @media screen and (min-width: 420.1px) {
      margin-bottom: var(--msd-space-3xl);
    }
  }
}
