@use '@angular/cdk/overlay-prebuilt.css';

.default-alert-position{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cdk-overlay-pane.pwa-alert{

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.cdk-overlay-backdrop.uploader, .cdk-overlay-pane.webform{
  background-color: transparent;
}

.cdk-overlay-pane.uploader, .cdk-overlay-pane.webform{

  @extend .default-alert-position;

  width: 420px;
  background-color: var(--msd-color-white);
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.2);
  border-radius: 5px;

}
