.msd-btn{

  display: flex;
  align-items: center;
  background-color: transparent;

  --btn-height: var(--msd-size-h-button);

  height: var(--btn-height);
  line-height: var(--btn-height);

  text-transform: uppercase;

  font-size: var(--msd-text-sm);

  &.absolute-unit{
    font-size: calc(1px*var(--msd-text-sm));
  }

  &.icon{

    justify-content: center;

    width: var(--btn-height);
    height: var(--btn-height);

    &.medium{
      --btn-height: var(--msd-size-h-button-medium);
      msd-ui-svg-icon{
        --icon-size: var(--msd-size-icon-medium);
      }
    }

    &.big{
      --btn-height: var(--msd-size-h-button-big);
      msd-ui-svg-icon {
        --icon-size: var(--msd-size-icon-big);
      }
    }

  }

  &.menu-link{
    padding: 0 var(--msd-space-sm);
  }

  &.contained{
    background-color: var(--msd-color-grey);
    color: var(--msd-color-white);
  }

  @media(hover: hover){
    &:hover{
      &.contained{
        background-color: var(--msd-color-grey-dark);
      }
    }
  }

  &.outlined{
    border: var(--msd-color-grey) 1px solid;
  }

  &.contained, &.outlined{
    padding: 0 var(--msd-space-lg);
  }

  span{
    width: 100%;
  }


}
