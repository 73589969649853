:root {

  --piwik-z-index: 2147483346;

}

body {
  #ppms_cm_consent_popup_74dfab82-68fe-4e9f-a4d7-ac75cce456d7, #ppms_cm_consent_popup_4dda05b3-ae80-4ee6-9f35-c429623e0633 {

    --color: var(--msd-color-grey);

    div.ppms_cm_popup_overlay span.ppms_cm_header1, div.ppms_cm_popup_overlay span.ppms_cm_header1:focus, div.ppms_cm_popup_overlay span.ppms_cm_header1:hover {
      color: var(--msd-color-grey-dark) !important;
    }

    div.ppms_cm_popup_overlay p.ppms_cm_paragraph, div.ppms_cm_popup_overlay p.ppms_cm_paragraph:focus, div.ppms_cm_popup_overlay p.ppms_cm_paragraph:hover {
      color: var(--color) !important;
    }

    .ppms_cm_language_select_btn {
      color: var(--color) !important;
    }

    .div.ppms_cm_popup_overlay span.ppms_cm_consent_item-text, div.ppms_cm_popup_overlay span.ppms_cm_consent_item-text:focus, div.ppms_cm_popup_overlay span.ppms_cm_consent_item-text:hover {
      color: var(--color) !important;
    }

    div.ppms_cm_popup_overlay button.ppms_cm_content_expander_show_btn, div.ppms_cm_popup_overlay button.ppms_cm_content_expander_show_btn:focus, div.ppms_cm_popup_overlay button.ppms_cm_content_expander_show_btn:hover{
      color: var(--color) !important;
      text-decoration: underline !important;
    }

    div.ppms_cm_popup_overlay a.ppms_cm_link{
      color: var(--color) !important;
      text-decoration: underline !important;
    }

    div.ppms_cm_popup_overlay div.ppms_cm_language_select_btn:hover {
      background-color: transparent !important;
    }

    svg{
      #ppms-f6a9f4c1-7ea9-4ab6-b62f-d7fcf91c42e3, #ppms-6b2ae768-66f7-4acb-845d-a107b5e946ec{
        fill: var(--msd-color-grey-dark);
      }
    }

    .ppms_cm_popup_overlay {

      background-color: lch(97 0.02 356.63) !important;
      max-width: 540px !important;
      box-shadow: none !important;
      color: var(--color) !important;
      bottom: 0rem!important;
      left: 0rem!important;
      z-index: var(--piwik-z-index) !important;

      @media screen and (min-width: 480px) {
        bottom: 1rem!important;
        left: 1rem!important;
      }

      .ppms_cm_agree-to-all, .ppms_cm_reject-all {
        margin: 0 !important;
        align-self: center !important;
        min-width: unset !important;
        flex: none !important;
        white-space: nowrap !important;
        padding: 0.6rem 1rem !important;
      }


      .ppms_cm_agree-to-all {
        font-weight: bold !important;
        text-transform: uppercase !important;
        background-color: var(--clr-dark-blue-base) !important;
        color: var(--msd-color-grey-dark) !important;
        border: none !important;
        border-radius: 999px !important;
      }

      .ppms_cm_reject-all {
        border: none !important;
        border-radius: 999px !important;
        background-color: transparent !important;
        color: var(--color) !important;
        margin-top: 1rem !important;
      }

      .ppms_cm_footer {

        background-color: transparent!important;
        opacity: 0.65 !important;

        .ppms_cm_footer__powered_by {
          padding-right: 0 !important;
          color: var(--color) !important;
        }

        .ppms_cm_footer__logo {
          transform: scale(0.75) !important;
        }
      }

      .ppms_cm_card_section {
        background-color: oklch(var(--clr-dark-blue-root)/0.05) !important;
        color: var(--color) !important;
        //border-color: oklch(var(--clr-dark-blue-root)/0.35) !important;
        border-width: 0 !important;
        border-radius: 0.5rem !important;
      }

    }

  }
}
