@use 'apps/massaud/src/styles/button';

option{
  appearance: none;
  background-color: var(--msd-color-white) !important;
}

msd-utils-webform {

  form {

    display: flex;
    flex-direction: column;
    gap: var(--msd-space);
    user-select: none;

    &.success {
      visibility: hidden;
    }

    .form-item {
      display: flex;
      flex-direction: column;

      > label {
        text-transform: uppercase;
        font-size: var(--msd-text-sm);
        user-select: none;
        color: var(--msd-color-grey);
      }

    }

    button {
      @extend .msd-btn;
      @extend .contained;
      align-self: center;
    }

    .input-text {
      background-color: var(--msd-color-grey-lightest);
      line-height: var(--msd-size);
      padding: 0 var(--msd-space);
      transition-property: background-color;
      transition-duration: var(--msd-transition-duration);
      border: var(--one-pixel) solid var(--msd-color-grey-lightest);
    }

    .input-text:focus {
      outline: none;
      background-color: var(--msd-color-grey-light);
      border: var(--one-pixel) solid var(--msd-color-grey);
    }

    .input-text.ng-invalid.ng-touched{
      border-color: var(--msd-color-error);
    }

    .error-group{
      color: var(--msd-color-error);
      font-size: var(--msd-text-sm);
    }

    .select-wrapper{

      position: relative;
      align-self: flex-start;
      pointer-events: none;

      &:after {
        content: "▼";
        font-size: 0.5rem;
        top: 6px;
        right: 10px;
        position: absolute;
      }

      select{
        appearance: none;
        pointer-events: auto;
        position: relative;
        line-height: calc(var(--msd-size) + var(--one-pixel)*2);
        height: calc(var(--msd-size) + var(--one-pixel)*2);
        padding-right: 2em !important;
        //border-width: 0 !important;
      }

    }

    .mandatory {
      font-size: var(--msd-text-xs);
    }

    .b-checkbox {

      display: flex;
      gap: var(--msd-space);

      input[type=checkbox] {
        width: var(--msd-size-sm);
        height: var(--msd-size-sm);
        border: var(--one-pixel) solid var(--msd-color-grey);
        position: relative;
        appearance: none;
        -webkit-appearance: none;
        display: none;
      }

      input[type=checkbox]:checked {
        &:after{
          content: '';
          position: absolute;
          inset: var(--one-pixel);
          background-color: var(--msd-color-grey);
        }
      }

      label{
        mat-icon{
          margin-right: var(--msd-space-sm);
        }
        span{
          vertical-align: text-bottom;
        }
      }

      input[type=checkbox] + label{
        .icon-checked{
          display: none;
        }
        .icon-unchecked{
          display: inline-block;
        }
      }
      input[type=checkbox]:checked + label{
        .icon-checked{
          display: inline-block;
        }
        .icon-unchecked{
          display: none;
        }
      }

    }

  }

  .b-success {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nx-massaud-m-loading {
    p {
      position: relative;
    }
  }

  > nx-massaud-m-loading {
    position: relative;
  }

  .b-form-submission-loading {
    nx-massaud-m-loading {
      position: absolute;
      background-color: var(--msd-color-white-opacity);
      pointer-events: auto;
    }
  }

}
