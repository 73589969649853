@use 'breakpoints' as Breakpoints;

@mixin buildSizeVars($map, $varPrefix) {
  @each $name, $sizes in $map {
    @each $size-name, $size in $sizes {
      @if $size-name == 'default' {
        --#{$varPrefix}-#{$name}: #{$size};
      } @else {
        --#{$varPrefix}-#{$name}-#{$size-name}: #{$size};
      }
    }
  }
}


$base-sizes: ("default":1.88rem, "xs":0.63rem, "sm":1.25rem, "md":2.5rem, "lg":3.13rem, "xl":3.75rem, "2xl":4.38rem, "3xl":5rem, "4xl":5.63rem, "5xl":6.25rem, "6xl":7.5rem, "7xl":8.75rem, "8xl":10rem, "9xl":11.25rem, "10xl":12.5rem);
$sizes-height: ("header": ('default': 80px, 'mini': 50px), "menu-item": ('default':2.5em), "button":('default':var(--msd-size-md), 'medium':var(--msd-size-xl), 'big':var(--msd-size-4xl)));
$sizes-leading: ("menu-item": ('default':38px));
$sizes-min-width: ("main-menu": ('default': 250px));
$sizes-width: ("partners-column": ('default': 1fr, 'small': 250px), "projects-column": ('default': 1fr, 'small': 360px));
$sizes-icon: ("default":1.25rem, "medium":1.88rem, "big":3.75em);
:root, :before, :after {

  @each $name, $size in $base-sizes {
    @if $name == 'default' {
      --msd-size: #{$size};
    } @else {
      --msd-size-#{$name}: #{$size};
    }
  }

  @each $name, $size in $sizes-icon {
    --msd-size-icon-#{$name}: #{$size};
  }

  @include buildSizeVars($sizes-width, msd-size-w);

  @include buildSizeVars($sizes-height, msd-size-h);

  @include buildSizeVars($sizes-leading, msd-size-leading);

  --msd-menu-width: 90vw;
  --msd-menu-max-width: 29em;

  --msd-size-slideshow-logo: var(--msd-size-8xl);
  @include Breakpoints.from(md){
    --msd-size-slideshow-logo: var(--msd-size-10xl);
  }
  --msd-size-news-item-h: 22.125em;

  --one-pixel: 0.0625em;

  --max-content-width: 1400px;

}

@each $name, $size in $base-sizes {
  .w-#{$name} {
    width: #{$size};
  }
  .h-#{$name} {
    height: #{$size};
  }
  .min-w-#{$name} {
    min-width: #{$size};
  }
  .min-h-#{$name} {
    min-height: #{$size};
  }
  .max-w-#{$name} {
    max-width: #{$size};
  }
  .max-h-#{$name} {
    max-height: #{$size};
  }
}

@each $name, $sizes in $sizes-height {
  .h-#{$name} {
    height: map-get($sizes, 'default');
  }
}

@each $name, $sizes in $sizes-min-width {
  .min-w-#{$name} {
    min-width: map-get($sizes, 'default');
  }
}

@each $name, $sizes in $sizes-height {
  .leading-#{$name} {
    line-height: map-get($sizes, 'default');
  }
}

msd-ui-grid-list:not(.partner-list){
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--max-content-width);
}

msd-ui-associated-projects{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  msd-ui-grid-list{
    max-width:unset !important;
  }
}

msd-det-ui-project{

  .b-title-features-infos{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: var(--max-content-width);
  }

}

mil-paragraph-rich-text.strip{
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-content-width);
}

msd-det-deat-partner {
  align-self: center;
  max-width: var(--max-content-width);
  width: 100%;
}

msd-det-ui-default-page{
  mil-paragraph-rich-text{
    position: relative;
    max-width: var(--max-content-width);
    margin-left: auto;
    margin-right: auto;
  }
}

msd-det-deat-page.contact, msd-det-deat-page.page-legal{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: var(--max-content-width);
}
