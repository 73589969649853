
@use 'sass-mq/mq' as mq with (
  $breakpoints: (
    xs: 420px,
    sm: 768px,
    md: 1024.1px,
    lg: 1280px,
    xl:1920px
  )
);

@mixin from($breakpoint){
  @include mq.mq($from: $breakpoint){
    @content;
  }
}

@mixin until($breakpoint){
  @include mq.mq($until: $breakpoint){
    @content;
  }
}

.mobile-only{
  @include until(xs){
    display: none;
  }
}

.from-small{
  display: none;
  @include from(sm){
    display: inherit;
  }
}

@mixin isTouch(){
  @media (pointer: coarse) {
    @content;
  }
}

@mixin isMouse(){
  @media (pointer: fine) {
    @content;
  }
}
