@use 'breakpoints' as Breakpoints;

@mixin grid-column-span($span) {
  grid-column: span $span;
}

@mixin massaudGrid($repeatSize, $repeatMode, $aspectRatio:null) {

  position: relative;
  display: block;

  ul {
    display: grid;
    grid-template-columns: repeat($repeatMode, $repeatSize);

    --column-gap:var(--main-grid-gap-x);
    --row-gap: var(--main-grid-gap-y);

    column-gap: var(--column-gap);
    row-gap: var(--row-gap);

    li {

      @if ($aspectRatio) {
        aspect-ratio: 4/3;
        background-color: var(--msd-color-grey-light);
      }

      msd-ui-grid-list-item {

        @if ($aspectRatio) {
          position: absolute;
          inset: 0;
        }

        a {
          @if ($aspectRatio) {
            position: absolute;
            inset: 0;
          }

          .title {
            display: none;
          }

          .b-media {
            width: 100%;
            @if ($aspectRatio) {
              height: 100%;
            }
            display: flex;
            align-items: center;
            justify-content: center;

          }
        }

      }

    }

  }

}

@mixin partnersGrid() {

  --column-size: 1fr;
  --repeat-mode: 3;

  @include Breakpoints.from(md) {
    --column-size: 1fr;
    --repeat-mode: 6;
  }

  @include massaudGrid(var(--column-size), var(--repeat-mode), 4/3);

  ul{

    --column-size: 1fr;
    --repeat-mode: 3;
    --column-gap:var(--main-grid-gap-x);
    --row-gap: var(--main-grid-gap-y);

    @include Breakpoints.from(md) {
      --column-size: 1fr;
      --repeat-mode: 6;
      --column-gap:var(--msd-space-8xl);
      --row-gap:var(--grid-gap-v);
    }

    column-gap: var(--column-gap) !important;
    row-gap: var(--row-gap) !important;

    li{
      filter: saturate(0);
      transition-property: filter;
      transition-duration: var(--msd-transition-duration);
    }

    li:hover{
      filter: saturate(1);
    }

  }

}

@mixin projectsGrid() {

  --column-count: 2;

  @include Breakpoints.from(md) {
    --column-count: 3;
  }

  @include massaudGrid(1fr, var(--column-count));

}

@mixin customGrid() {

  --column-count: 1;
  @include Breakpoints.from(sm) {
    --column-count: 12;
    ul {
      li {
        @for $i from 3 through 12 {
          &.span-#{$i} {
            @include grid-column-span(#{$i});
          }
        }
      }
    }
  }
  @include massaudGrid(1fr, var(--column-count));

}

[msdutilscssgridmanager]{
  &.grid-layout-active{
    background-color: aliceblue;
    display: grid;
  }
  &.grid-template-columns{
    grid-template-columns: repeat(var(--repeat-column-count), var(--repeat-column-track));
  }
  &.grid-template-rows{
    grid-template-rows: repeat(var(--repeat-row-count), var(--repeat-row-track));
  }
}
