mil-strip {

  mil-paragraph-rich-text {
    h2 {
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .strip-image {
    li.images {

      max-width: 100vw;

      @media screen and (min-width: 480px) {
        max-width: 85vw;
      }

      img {
        object-fit: contain;
      }
    }
  }

}

mil-paragraph-rich-text.strip-text-header {
  @media screen and (max-width: 480px) {
    padding-top: var(--grid-gap-v);
  }
}

mil-layout {
  > ul {
    li.images {
      &.object-fit-contain {
        i2l-media-image {
          width: auto !important;
          height: 100% !important;

          img {
            width: auto !important;
            //height: 100% !important;
            object-fit: contain !important;
          }
        }
      }
    }
  }
}

mil-strip {
  mil-layout {
    .paragraph--text{
      @media screen and (max-width: 480px) {
        display: none !important;
      }
    }
  }
}

.theme-dark {
  background-color: var(--msd-color-grey-dark);
  color: var(--msd-color-white);
}

.theme-medium {
  background-color: var(--msd-color-grey);
  color: var(--msd-color-white);
}

.theme-lighter {
  background-color: var(--msd-color-white);
  color: var(--msd-color-grey-light);
}

.has-max-width {
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  display: block;
}

mil-layout.duo {

  display: block;

  > ul {
    display: flex;
    overflow: hidden;
    flex-direction: column;

    @media screen and (min-width: 480px) {
      flex-direction: row;
    }

    > li {

      @media screen and (min-width: 480px) {
        flex: 0 0 50% !important;
      }

      overflow: hidden;

      > mil-paragraph-rich-text {
        width: 100%;
        justify-content: flex-start !important;
      }
    }

    > li.paragraph--media_gallery {

      > mil-layout {
        display: block;

        > ul {
          > li {
            width: 100%;
            padding-right: 0 !important;

            i2l-media-image {
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }

}

ul[class*="grid-rows-"] {
  --i2l-media-image-height: 100%;
  --i2l-media-image-img-width: 100%;

  i2l-media-image {
    --prevent-resize: 1;
  }

  gap: 0 !important;
}


.grid-rows-2 {

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: row;
    aspect-ratio: unset;
    li {
      aspect-ratio: 1;
      scroll-snap-stop: always;
      scroll-snap-align: center;
    }

    li.paragraph--media_gallery {
      order: 0;
    }

    li.paragraph--text {
      order: 1;
    }
  }

  mil-paragraph-rich-text {
    div[i2lDrupalRichTextLink] {
      @media screen and (max-width: 480px) {
        padding: 0 1rem;
        h2, h3 {
          font-size: var(--msd-text-md) !important;
        }
        p {
          margin: 0;
          font-size: var(--msd-text-sm) !important;
        }
      }
    }
  }
}
