[i2lDrupalRichTextLink] {

  h2{
    font-size: var(--msd-text-xl);
  }

  p{
    font-size: var(--msd-text);
    margin: var(--msd-space) 0;
  }

  ul{
    padding-left: var(--msd-space-xl);
    margin: var(--msd-space) 0;
  }

  a[href^="http"] {
    text-decoration: underline;
  }

  a:not([href^="http"]) {
    position: relative;
    padding: 0 var(--msd-space-xxs);
    text-decoration: none;
  }

  a:not([href^="http"]):before {
    content: '';
    position: absolute;
    inset: calc(var(--msd-space-xxs) /2 *-1) 0 calc(var(--msd-space-xxs)*-1) 0;
    z-index: -1;
    border: var(--one-pixel) solid var(--msd-color-grey-light);
  }

  @media (hover: hover) {
    a:not([href^="http"]):hover:before {
      border: var(--one-pixel) solid var(--msd-color-grey-dark);
    }
  }

}

mil-paragraph-rich-text:not([class^="msd-text-"]) {

  [i2lDrupalRichTextLink] {
    h2{
      font-size: var(--msd-text-xl)!important;
    }
  }
}
