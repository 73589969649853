
$font-sizes: ("xs": 0.75rem, "sm": 0.88rem, "base": 1rem, "md":1.13rem, "lg":1.25rem, "xl":1.88rem, "2xl":2.5rem, "3xl":3.13rem, "4xl":4.06rem);
$font-weights: ("light": 300, "normal": 400, "bold": 500);

:root {

  --text-base: 16;

  @each $name, $size in $font-sizes {
    --msd-text-#{$name}: #{$size};
  }

  @each $name, $weight in $font-weights {
    --msd-font-#{$name}: #{$weight};
  }

  --msd-letter-spacing: var(--one-pixel);

}

@each $name, $size in $font-sizes {
  .msd-text-#{$name} {
    font-size: #{$size};
  }
  .h2-text-#{$name} {
    h2{
      font-size: #{$size}!important;
    }
  }
}

@each $name, $weight in $font-weights {
  .msd-font-#{$name} {
    font-weight: #{$weight};
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    font-weight: 400;
  }
}

.absolute-font-size{
  font-size: calc(1px*var(--text-base));
}
