:root{

  --msd-transition-duration: 0.4s;

}

@keyframes animation-creation {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes animation-downloading {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(359deg);
  }
}

.animate-downloading{
  animation: animation-downloading 2s infinite linear;
}
