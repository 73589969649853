/* barlow-light */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 300;
  src: local(''),
  url('../assets/fonts/barlow-light.woff2') format('woff2');
  font-display: swap;
}
/* barlow-regular */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('../assets/fonts/barlow-regular.woff2') format('woff2');
  font-display: swap;
}
/* barlow-regular-italic */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 400;
  src: local(''),
  url('../assets/fonts/barlow-regular-italic.woff2') format('woff2');
  font-display: swap;
}
/* barlow-medium */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('../assets/fonts/barlow-medium.woff2') format('woff2');
  font-display: swap;
}
/* barlow-medium-italic */
@font-face {
  font-family: 'Barlow';
  font-style: italic;
  font-weight: 700;
  src: local(''),
  url('../assets/fonts/barlow-medium-italic.woff2') format('woff2');
  font-display: swap;
}
