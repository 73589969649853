:root, :before, :after {

  --msd-color-white: hsla(0, 100%, 100%, 100%);
  --msd-color-white-opacity: hsla(0, 100%, 100%, 90%);
  --msd-color-black-opacity: hsla(0, 100%, 0%, 80%);
  --msd-color-grey-dark: hsla(0, 0%, 32%, 100%);
  --msd-color-grey-medium: hsla(0, 0%, 50%, 50%);
  --msd-color-grey-light: hsla(0, 0%, 80%, 100%);
  --msd-color-grey-lightest: hsla(223.81, 0%, 95%,100%);
  --msd-color-grey: hsla(0, 0%, 50%, 100%);
  --msd-color-error: #DF2C2C;

}

.bg-white{
  background-color: var(--msd-color-white);
}

.bg-white-opacity{
  background-color: var(--msd-color-white-opacity);
}

.bg-black-opacity{
  background-color: var(--msd-color-black-opacity);
}

.bg-grey-dark{
  background-color: var(--msd-color-grey-dark);
}

.bg-grey-light{
  background-color: var(--msd-color-grey-light);
}

.bg-grey-lightest{
  background-color: var(--msd-color-grey-lightest);
}

.bg-grey{
  background-color: var(--msd-color-grey);
}

.text-white{
  color: var(--msd-color-white);
}

.text-white-opacity{
  color: var(--msd-color-white-opacity);
}

.text-black-opacity{
  color: var(--msd-color-black-opacity);
}

.text-grey-dark{
  color: var(--msd-color-grey-dark);
}

.text-grey-light{
  color: var(--msd-color-grey-light);
}

.text-grey{
  color: var(--msd-color-grey);
}

.text-error{
  color: var(--msd-color-error);
}
