@use 'styles/reset';
@use 'styles/fonts';
@use 'styles/colors';
@use 'styles/typography';
@use 'styles/sizes';
@use 'styles/functional';
@use 'styles/spaces';
@use 'styles/animations';
@use 'styles/blend-modes';
@use 'grid';
@use 'rich-text';
@use 'styles/button';
@use 'styles/overlay';
@use 'form';
@use 'styles/paragraphs';
@use 'contact';
@use 'styles/piwik-consent';
:root{
  --msd-font-family: 'Barlow';
}

html {
  font-family: var(--msd-font-family), sans-serif;
  color: var(--msd-color-grey);
  font-size: var(--text-base);
  font-weight: var(--msd-font-normal);
}

body{
  padding: 0 var(--app-padding-x);
}

::selection {
  background: var(--msd-color-grey); /* This sets the background color of selected text */
  color: white; /* This sets the text color of selected text */
}
