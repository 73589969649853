$positions: static, fixed, absolute, relative, sticky;

@each $position in $positions {
  .#{$position} {
    position: $position;
  }
}

.list-style-type-none {
  list-style-type: none;
}

.text-uppercase, .uppercase {
  text-transform: uppercase;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto
}

.app-negative-x-margin {
  margin: 0 calc(var(--app-padding-x) * -1);
}

.block {
  display: block;
}

.aspect-ratio-1-1 {

  aspect-ratio: 1/1;
  flex: none;
  height: 100%;

}

.aspect-ratio-3-1 {

  aspect-ratio: 3/1;
  flex: none;
  height: 100%;

}

.aspect-ratio-4-3 {

  aspect-ratio: 4/3;
  flex: none;
  height: 100%;

}

.aspect-ratio-16-9 {

  aspect-ratio: 16/9 !important;
  flex: none !important;
  height: 100% !important;

}

.flex {
  display: flex;
}

.flex-none {
  flex: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.overflow-hidden {
  overflow: hidden;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.grid {
  display: grid;
}

.grid-rows-1{
  grid-template-rows: repeat(1, 100%);
}

.grid-rows-2{
  grid-template-rows: repeat(2, 50%);
}

.grid-rows-3{
  grid-template-rows: repeat(3, calc(100/3*1%));
}

.grid-flow-col{
  grid-auto-flow: column;
}

.h-full {
  height: 100%;
}

.w-screen{
  width: 100vw;
}

.max-w-screen{
  max-width: 100vw;
}

.h-screen{
  height: 100vh;
}

.max-h-screen{
  max-height: 100vh;
}

@for $i from 1 through 9 {

  .w-screen-#{$i*10}{
    width: $i*10vw;
  }

  .max-w-screen-#{$i*10}{
    max-width: $i*10vw;
  }

  .h-screen-#{$i*10}{
    height: $i*10vh;
  }

  .max-h-screen-#{$i*10}{
    max-height: $i*10vh;
  }

  .min-h-screen-#{$i*10}{
    min-height: $i*10vh;
  }

}

@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
}

@for $i from 1 through 12 {
  .col-span-#{$i}{
    grid-column: span $i / span $i;
  }
}

.col-span-full{
  grid-column: 1/-1;
}


$justify-align-values: ("auto": auto, "start":flex-start, "end": flex-end, "center": center, "stretch":stretch, "baseline":baseline);

@each $name, $value in $justify-align-values {
  .align-self-#{$name}{
    align-self: $value;
  }
  .justify-self-#{$name}{
    justify-self: $value;
  }
}
